import axios from 'axios'
import { PRINT_API } from '../constants/Constants'

const httpLocal = axios.create({
  baseURL: PRINT_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

httpLocal.interceptors.request.use(
  (config) => {
    // Handle headers based on request type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type']
    } else if (config.url === '/print') {
      config.headers['Content-Type'] = 'application/pdf'
    } else {
      config.headers['Content-Type'] = 'application/json'
    }

    return config
  },
  (error) => Promise.reject(error),
)

export default httpLocal
