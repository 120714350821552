import httpLocal from '../utils/http-local'

export const getPrinters = () => {
  return httpLocal.get('/printers')
}

export const addPrinter = (data) => {
  return httpLocal.post('/printer', data)
}

export const print = (data) => {
  return httpLocal.post('/print', data)
}

export const getScale = () => {
  return httpLocal.get('/usb')
}

export const addScale = (data) => {
  return httpLocal.post('/scale', data)
}

export const setAutoPrint = (data) => {
  return httpLocal.post('/setAutoPrint', data)
}

const PrintDataService = {
  getPrinters,
  addPrinter,
  print,
  getScale,
  addScale,
  setAutoPrint,
}

export default PrintDataService
