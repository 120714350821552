import { DEVICES } from 'src/constants/Constants'
import { PRINTER_DATA, SCALE_DATA, SET_DEVICES } from '../actions/actionType'
const initialState = {
  devices: localStorage.getItem(DEVICES) || { printer: '', scale: '' },
  printers: [],
  scales: [],
}
export default function DeviceReducer(state = initialState, action) {
  switch (action.type) {
    case PRINTER_DATA:
      return { ...state, printers: action.payload }
    case SCALE_DATA:
      return { ...state, scales: action.payload }
    case SET_DEVICES:
      localStorage.setItem(DEVICES, JSON.stringify(action.payload))
      return { ...state, devices: action.payload }
    default:
      return state
  }
}
